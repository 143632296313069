$(document).ready(function(){

  if ($('.s-fundraising').length > 0){

    var filter = $('.s-fundraising__filter');
    var query = $('.s-fundraising__query');
    var type = $('.s-fundraising__type');
    var country = $('.s-fundraising__country');
    var submit = $('.s-fundraising__submit');

    type[0].addEventListener('selection', function (e) {
      if (type.val() == '*'){
        type.val('');
      }
      filter.submit();
    }, false);

    country[0].addEventListener('selection', function (e) {
      if (country.val() == '*'){
        country.val('');
      }
      filter.submit();
    }, false);

    submit.click(function(){
      filter.submit();
    });

  }

});
