$(document).ready(function(){

    if ( $('#id_latest_3').is(':checked') ){
        $('input[name="methods"]').attr('disabled', true);
        $('input[name="newsletters"]').attr('disabled', true);

    } else if ($('#id_latest_2').is(':checked')){
        $('input[name="methods"]').attr('disabled', true);
        $('input[name="newsletters"]').attr('disabled', true);

    } else {
        $('input[name="methods"]').removeAttr('disabled');
        $('input[name="newsletters"]').removeAttr('disabled');
    }
    
    $('input[name="latest"]').change(function(){

        if ( $('#id_latest_3').is(':checked') ){
            $('input[name="methods"]').attr('disabled', true);
            $('input[name="newsletters"]').attr('disabled', true);

        } else if ($('#id_latest_2').is(':checked')){
            $('input[name="methods"]').attr('disabled', true);
            $('input[name="newsletters"]').attr('disabled', true);

        } else {
            $('input[name="methods"]').removeAttr('disabled');
            $('input[name="newsletters"]').removeAttr('disabled');
        }
        
    });

});