$(document).ready(function(){

  if ($('.s-product').length > 0){

    if ($('.s-product--downloadable').length === 0){

      var product = $('.s-product__id');
      var selections = $('.s-product__variation');
      var price = $('.s-product__price');
      var warning = $('.c-warning');
      var addtocart = $('.s-product__addtocart');
      var form = $('.s-product__addform');
      var quantity_el = $(".c-number__input[name='quantity']");
      var price_el = $("input[name='price']");
      var cartupdate = $('.s-cartupdate');
      var total_el = $('.s-headlogo__total');
      var cart_el = $('.s-headlogo__cart');

      function update_price(){
        var params = [];
        selections.each(function(){
          var id = parseInt($(this).attr('name').replace('selection-', ''));
          var value = $(this).val();
          var option = {
            'id': id,
            'value': value,
          }
          params.push(option)
        });

        $.ajax({
          type: 'post',
          url: '/ajax/shop/price/',
          data: {
            'product': product.attr('data-id'),
            'options': JSON.stringify(params),
          },
          traditional: true,
          success: function (data) {

            if (data.price != 'Free'){
              price_el.val(data.price);
              price.text(data.price.toFixed(2));
            }

            if (data.price == 'Free'){
              price.prev().text('');
              price.next().text('');
              price.text('');
            }

            if (data.quantity == 0){
              warning.addClass('c-warning--unavailable');
              addtocart.addClass('s-product__addtocart--disabled');
              addtocart.text('Out of Stock');
            } else {
              warning.removeClass('c-warning--unavailable');
              addtocart.removeClass('s-product__addtocart--disabled');
              addtocart.text('Add To My Cart');
            }

            if (data.quantity == 'Infintate'){
              //quantity_el.val(1);
            } else {
              quantity_el.attr('max', data.quantity);
              if ( parseInt(quantity_el.val()) > data.quantity ){
                quantity_el.val(data.quantity);
                warning.addClass('c-warning--adjusted');
              }
            }

          }

        });

      }

      update_price();
      selections.change(function(){
        update_price();
      });

      form.submit(function(evt){
        evt.preventDefault();

        $.ajax({
          type: 'post',
          url: '/ajax/shop/add-to-cart/',
          data: form.serialize(),
          traditional: true,
          success: function (data) {

            $('.s-cartupdate_name').text(data.product_title);
            $('.s-cartupdate_quantity').text(data.product_quantity);
            update_price();

            if (data.total == null){
              data.total = 0;
            }

            total_el.text(data.total.toFixed(2));
            $('.s-headlogo__quantity').text('(' + data.new_basket_quantity + ' items)');

            if (!cart_el.hasClass('s-headlogo__cart--show')){
              cart_el.addClass('s-headlogo__cart--show');
            }

            cartupdate.addClass('s-cartupdate--show');
            setTimeout(function () {
              cartupdate.removeClass('s-cartupdate--show');
            }, 5000);

          }

        });

      });
    }
  }
});
