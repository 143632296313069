$(document).ready(function(){

  $('#id_same').change(function(){
    var same = $(this);
    if (same.prop('checked')){
      $('#id_bill_first_name').val('').attr('disabled', 'disabled');
      $('#id_bill_last_name').val('').attr('disabled', 'disabled');
      $('#id_bill_address').val('').attr('disabled', 'disabled');
      $('#id_bill_town_city').val('').attr('disabled', 'disabled');
      $('#id_bill_county_state').val('').attr('disabled', 'disabled');
      $('#id_bill_post_code').val('').attr('disabled', 'disabled');
      $('#id_bill_country').val('').attr('disabled', 'disabled');
    } else {
      $('#id_bill_first_name').val('').removeAttr('disabled');
      $('#id_bill_last_name').val('').removeAttr('disabled');
      $('#id_bill_address').val('').removeAttr('disabled');
      $('#id_bill_town_city').val('').removeAttr('disabled');
      $('#id_bill_county_state').val('').removeAttr('disabled');
      $('#id_bill_post_code').val('').removeAttr('disabled');
      $('#id_bill_country').val('').removeAttr('disabled');
    }
  });

});