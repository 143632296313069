$(document).ready(function(){

  function show(){
    var totop = $('.s-totop');
    var scroll_position = $(window).scrollTop();
    var window_height = $( window ).height();

    if (totop.hasClass('s-totop--show')){
      if (scroll_position < (window_height / 2)){
        totop.removeClass('s-totop--show');
      }
    } else {
      if (scroll_position >= (window_height / 2)){
        totop.addClass('s-totop--show');
      }
    }
  }

  $('.s-totop__block').click(function(evt){
    evt.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  });

  show();
  $(window).scroll(function(){
    show();
  });

});