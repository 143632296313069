$(document).ready(function(){



  $('.c-signup').each(function(){
    var signup = $(this);
    var trigger = signup.find('.c-signup__trigger');
    var action = signup.find('.c-signup__action');
    var required = signup.find('input[required], select[required]');
    var fields = signup.find('input, select');

    trigger.prop('checked', false);
    action.css('display', 'none');
    required.removeAttr('required');

    function show_hide(){
      if (trigger.prop('checked')){
        action.css('display', 'block');
        required.attr('required', 'required');

        var first_name_value = '';
        var first_name_found = $("input[placeholder='First Name']").first();
        var first_name = $("#id_first_name");
        if (first_name != first_name_found){
          first_name_value = first_name_found.val();
        }

        var last_name_value = '';
        var last_name_found = $("input[placeholder='Last Name']").first();
        var last_name = $("#id_last_name");
        if (last_name != last_name_found){
          last_name_value = last_name_found.val();
        }

        var email_address_value = '';
        var email_address_found = $("input[placeholder='Email Address']").first();
        var email_address = $("#id_email_address");
        if (email_address != email_address_found){
          email_address_value = last_name_found.val();
        }

        first_name.val(first_name_value);
        last_name.val(last_name_value);
        email_address.val(email_address_value);

      } else {
        action.css('display', 'none');
        required.removeAttr('required');
        fields.val('');

      }
    }

    trigger.change(function(){
      show_hide();
    });

  });

});