$(document).ready(function(){

  // Active State

  $('.c-smenu__check[data-slug="' + $('.c-smenu--cache').attr('data-slug') + '"]').addClass(
    $('.c-smenu__check[data-slug="' + $('.c-smenu--cache').attr('data-slug') + '"]').attr('data-active')
  );

  $('.c-smenu__menu-navi').click(function(evt){
    evt.preventDefault();
    var menu_navi = $(this);
    var wrap = menu_navi.closest('.c-smenu__wrap');
    wrap.toggleClass('c-smenu__wrap--open');
    if (!wrap.hasClass('c-smenu__wrap--open')){
      wrap.find('.c-smenu__wrap--open').removeClass('c-smenu__wrap--open');
    }
  });

  var active = $('.c-smenu__active--activated');
  if (active.length > 0){
    lvl_up_1 = active.parents('.c-smenu__wrap');
    if (lvl_up_1.length > 0){
      lvl_up_1.addClass('c-smenu__wrap--open');
      lvl_up_2 = lvl_up_1.parents('.c-smenu__wrap');
      if (lvl_up_2.length > 0){
        lvl_up_2.addClass('c-smenu__wrap--open');
        lvl_up_3 = lvl_up_2.parents('.c-smenu__wrap');
        if (lvl_up_3.length > 0){
          lvl_up_3.addClass('c-smenu__wrap--open');
        }
      }
    }
  }

});
