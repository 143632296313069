$(document).ready(function(){

  $('.c-scale').each(function(){
    var scale = $(this);
    var target = scale.find('.c-scale__target');
    var mark = scale.find('.c-scale__mark');
    var bar = scale.find('.c-scale__bar');
    var align = scale.find('.c-scale__align');

    var mark_value = parseInt(scale.attr('data-mark'));
    var target_value = parseInt(scale.attr('data-target'));
    var prefix = scale.attr('data-prefix')
    var suffix = scale.attr('data-suffix')

    align.html(prefix + mark_value + suffix);
    target.html(prefix + target_value + suffix)

    var bar_width = scale.width();
    var mark_width = align.width();

    var mark_position = mark_value / target_value;

    mark.css('margin-left', (mark_position * 100) + '%');
    bar.css('width', (mark_position * 100) + '%');

    console.log(mark_value, align.offset().left + align.width(), scale.offset().left + scale.width());

    if (align.offset().left < scale.offset().left){
      align.addClass('c-scale__align--left');
    }

    if (align.offset().left + align.width() > scale.offset().left + scale.width()){
      align.addClass('c-scale__align--right');
    }

  });

});