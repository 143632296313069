$(document).ready(function(){

  if ($('.s-f-subcategory').length > 0){

    var filter = $('.s-f-subcategory__filter');
    var sort = $('.s-f-subcategory__sort');

    sort[0].addEventListener('selection', function (e) {
      filter.submit();
    }, false);

  }

});
