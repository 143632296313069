$(document).ready(function(){

  // function setCookie(c_name, value, exdays) {
  //   var exdate = new Date();
  //   exdate.setDate(exdate.getDate() + exdays);
  //   var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString())
  //   document.cookie = c_name + "=" + c_value + ';path=/';
  // }

  // function getCookie(c_name) {
  //   var i, x, y, ARRcookies = document.cookie.split(";");
  //   for (i = 0; i < ARRcookies.length; i++) {
  //       x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
  //       y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
  //       x = x.replace(/^\s+|\s+$/g, "");
  //       if (x == c_name) {
  //           return unescape(y);
  //       }
  //   }
  // }

  // console.log('theme', getCookie('theme'));

  // if (!getCookie('theme')){
  //   setCookie('theme', 'standard', 365);
  // }
  // if (getCookie('theme') == 'grayscale'){
  //   $('#pagestyle').attr('href','/static/scss/public/styles_1-min.css');
  // }

  // $('.s-headbar__topright-inner, .s-headbar__topright-outer').click(function(evt){
  //   console.log('Click');
  //   evt.preventDefault();
  //   if (getCookie('theme') == 'standard'){
  //     setCookie('theme', 'grayscale', 365);
  //     $('#pagestyle').attr('href','/static/scss/public/styles_1-min.css');
  //   } else {
  //     setCookie('theme', 'standard', 365);
  //     $('#pagestyle').attr('href','/static/scss/public/styles-min.css');
  //   }
  // });

});