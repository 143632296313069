$(document).ready(function(){
  $('.c-accordion__header').click(function(evt){
    evt.preventDefault();
    var header = $(this);
    var fold = header.closest('.c-accordion__fold');
    var accordion = fold.closest('.c-accordion');

    if (fold.hasClass('c-accordion__fold--open')){
      fold.removeClass('c-accordion__fold--open');
    } else {
      accordion.find('.c-accordion__fold--open').removeClass('c-accordion__fold--open');
      fold.addClass('c-accordion__fold--open');
    }
  });

});