$(document).ready(function(){
  $('.c-expander__heading').click(function(evt){
    evt.preventDefault();
    var heading = $(this);
    var expander = heading.closest('.c-expander');
    if (expander.hasClass('c-expander--open')){
      expander.removeClass('c-expander--open');
    } else {
      expander.addClass('c-expander--open');
    }
  });
});