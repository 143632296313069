$(document).ready(function(){

  $('.c-file').each(function(){
    var file = $(this);
    var input = file.find('.c-file__input');
    var value = file.find('.c-file__value');
    var filename = input.val().split('\\').pop();
    if (filename){
      value.html('- ' + filename);
    }
  });

  $('.c-file__input').change(function(){
    var input = $(this);
    var file = input.closest('.c-file');
    var value = file.find('.c-file__value');
    var filename = input.val().split('\\').pop();
    if (filename){
      value.html('- ' + filename);
    }
  });

});