$(document).ready(function(){

  if ($('.s-products--main').length > 0){

    var filter = $('.s-products__filter');
    var category = $('.s-products__category');
    var sort = $('.s-products__sort');

    category[0].addEventListener('selection', function (e) {
      if (category.val() == '*'){
        category.val('');
      }
      filter.submit();
    }, false);

    sort[0].addEventListener('selection', function (e) {
      if (sort.val() == '*'){
        sort.val('');
      }
      filter.submit();
    }, false);

  }

});