//window.$ = window.jQuery = require('jquery');
require('bootstrap');
require('owl.carousel');
//require('jquery-match-height');
//require('bootstrap-datepicker');

require('./block/c-testimonials.js');
require('./block/c-smenu.js');
require('./block/c-accordion.js');
require('./block/c-select.js');
require('./block/c-number.js');
require('./block/c-rate.js');
require('./block/c-search.js');
require('./block/c-summary.js');
require('./block/c-expander.js');
require('./block/c-preset.js');
require('./block/c-file.js');
require('./block/c-panel.js');
require('./block/c-scale.js');
require('./block/c-extra.js');
require('./block/c-cprod.js');
require('./block/c-burger.js');
require('./block/c-submenu.js');
require('./block/c-signup.js');
//require('./block/c-event.js');

require('./block/s-banner.js');
require('./block/s-role.js');
require('./block/s-terms.js');
require('./block/s-totop.js');
require('./block/s-menu.js');
require('./block/s-news.js');
require('./block/s-event.js');
require('./block/s-fundraising.js');
require('./block/s-products.js');
require('./block/s-product.js');
require('./block/s-rs-opps.js');
require('./block/s-f-category.js');
require('./block/s-f-subcategory.js');
require('./block/s-cartupdate.js');
require('./block/s-billing.js');
require('./block/s-mmmenu.js');
require('./block/s-cookies.js');
require('./block/s-headbar.js');
require('./block/s-access.js');
require('./block/s-form.js');

require('./block/newsletter.js');
