$(document).ready(function(){

  $('.c-search__text').click(function(evt){
    evt.preventDefault();
    var text = $(this);
    var search = text.closest('.c-search');
    search.toggleClass('c-search--open');
    $('.c-search__input').focus();
  });

});