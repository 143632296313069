$(document).ready(function(){

  $('.c-panel').click(function(evt){
    evt.preventDefault();
    var panel = $(this);
    var lmember_ajax = $('.s-lmember__ajax');
    var lmember_modal = $('#lmemberModal');

    var lmenber_id = panel.attr('data-id');
    lmember_ajax.load('/lmember-ajax/' + lmenber_id + '/');
    lmember_modal.modal('show');
  });

});