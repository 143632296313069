$(document).ready(function(){

  var scaling = 0.1

  function setCookie(c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString())
    document.cookie = c_name + "=" + c_value + ';path=/';
  }

  function getCookie(c_name) {
    var i, x, y, ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x == c_name) {
            return unescape(y);
        }
    }
  }

  function standard_colour(){
    $('.s-body').removeClass('s-body--grayscale');
    if ($('#pagestyle').attr('href') != '/static/scss/public/styles-min.css'){
      $('#pagestyle').attr('href','/static/scss/public/styles-min.css');
    }
  }

  function grayscale(){
    $('.s-body').addClass('s-body--grayscale');
    if ($('#pagestyle').attr('href') != '/static/scss/public/styles-min.css'){
      $('#pagestyle').attr('href','/static/scss/public/styles-min.css');
    }
  }

  function contrast(){
    $('.s-body').removeClass('s-body--grayscale');
    if ($('#pagestyle').attr('href') != '/static/scss/public/styles_1-min.css'){
      $('#pagestyle').attr('href','/static/scss/public/styles_1-min.css');
    }
  }

  function readable(){
    $('.s-body').removeClass('s-body--grayscale');
    if ($('#pagestyle').attr('href') != '/static/scss/public/styles_2-min.css'){
      $('#pagestyle').attr('href','/static/scss/public/styles_2-min.css');
    }
  }

  $('.s-headbar__topright-inner, .s-headbar__topright-outer').click(function(evt){
    evt.preventDefault();
    $('.s-access__controls').addClass('s-access__controls--active');
  });

  $('.s-access__close').click(function(evt){
    evt.preventDefault();
    $('.s-access__controls').removeClass('s-access__controls--active');
  })

  $('.s-access__toggle').click(function(evt){
    evt.preventDefault();
    var id = $(this).attr('data-id');

    if ($(this).hasClass('s-access__toggle--active')){
      $(this).removeClass('s-access__toggle--active');
      setCookie('colour', '0', 365);
      standard_colour();
    } else {
      $('.s-access__toggle').removeClass('s-access__toggle--active');
      $('.s-access__toggle--'+ id).addClass('s-access__toggle--active');
      setCookie('colour', id, 365);
      if (id == '1') grayscale();
      if (id == '2') contrast();
      if (id == '3') readable();
    }
  });

  $('.s-access__size').click(function(evt){
    evt.preventDefault();

    var id = $(this).attr('data-id');
    if ($(this).hasClass('s-access__size--active')){
      $(this).removeClass('s-access__size--active');
      setCookie('size', '0', 365);

      $('body *').each(function() {
        var fs = parseInt($(this).attr('data-fs'));
        $(this).css('font-size', fs + 'px');
        //var lh = parseInt($(this).css('line-height'));
        //$(this).css('line-height', lh + 'px');
      });

    } else {
      $('.s-access__size').removeClass('s-access__size--active');
      $('.s-access__size--'+ id).addClass('s-access__size--active');
      setCookie('size', id, 365);

      $('body *').each(function() {
        var fs = parseInt($(this).attr('data-fs'));
        var nfs = fs * (1 + (id * scaling));
        $(this).css('font-size', nfs + 'px');
        //var lh = parseInt($(this).css('line-height'));
        //var nlh = lh * (1 + (size * 0.2));
        //$(this).css('line-height', nlh + 'px');
      });

    }
  });

  $('.s-access__cancel').click(function(evt){
    evt.preventDefault();
    $('.s-access__toggle').removeClass('s-access__toggle--active');
    setCookie('colour', '0', 365);
    $('.s-access__size').removeClass('s-access__size--active');
    setCookie('size', '0', 365);
    standard_colour();
    $('body *').each(function() {
      var fs = parseInt($(this).attr('data-fs'));
      $(this).css('font-size', fs + 'px');
      //var lh = parseInt($(this).css('line-height'));
      //$(this).css('line-height', lh + 'px');
    });
  });

  // Preset settings

  if (!getCookie('colour')){
    setCookie('colour', '0', 365);
  }
  if (!getCookie('size')){
    setCookie('size', '0', 365);
  }

  $('body *').each(function() {
    $(this).attr('data-fs', parseInt($(this).css('font-size')));
    //$(this).attr('data-lh', parseInt($(this).css('line-height')));
  });

  var resizeTimeout;
  var resizeWidth = $(window).width();
  window.addEventListener('resize', function(event) {
    if ($(window).width() != resizeWidth){
      clearTimeout(resizeTimeout);
      resizeWidth = $(window).width();
      resizeTimeout = setTimeout(function(){
        window.location.reload();
      }, 500);
    }
  });

  // Action on set settings

  var colour = getCookie('colour');
  if (colour != '0'){
    $('.s-access__toggle--' + colour).addClass('s-access__toggle--active');
    if (colour == '1') grayscale();
    if (colour == '2') contrast();
    if (colour == '3') readable();
  }

  var size = getCookie('size')
  if ( size != '0'){
    $('.s-access__size--' + size).addClass('s-access__size--active');

    $('body *').each(function() {
      var fs = parseInt($(this).attr('data-fs'));
      var nfs = fs * (1 + (size * scaling));
      $(this).css('font-size', nfs + 'px');
      //var lh = parseInt($(this).css('line-height'));
      //var nlh = lh * (1 + (size * 0.2));
      //$(this).css('line-height', nlh + 'px');
    });

  }

});