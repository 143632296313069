$(document).ready(function(){

  if ($('.s-billing').length > 0){

    var shipping_fields = $('.s-billing__ship-fields');
    var ship_to_bill = $('.s-billing__ship-to-bill');
    var billing_fields = $('.s-billing__bill-fields');
    var updates = ship_to_bill.prop('checked');

    function update_fields(){
      shipping_fields.find('input, select').each(function(){
        var field_id = $(this).attr('id').replace('_ship_', '_bill_');
        var field_value = $(this).val();
        console.log('field_id', field_id, 'field_value', field_value);
        billing_fields.find('#' + field_id).val(field_value);
      });
    }

    ship_to_bill.change(function(){
      updates = this.checked
      if (updates){
        update_fields();
      }
    })

    shipping_fields.find('input, select').keyup(function(){
      if (updates){
        update_fields();
      }
    });

    billing_fields.find('input, select').keyup(function(){
      ship_to_bill.prop('checked', false);
    });

  }

});