$(document).ready(function(evt){
  $('.c-burger').click(function(evt){
    evt.preventDefault();
    if ($('.s-mmenu').hasClass('s-mmenu--show')){
      $('.s-mmenu').removeClass('s-mmenu--show');
      $('.s-headbar__gte').append( $('#google_translate_element') );
    } else {
      $('.s-mmenu').addClass('s-mmenu--show');
      $('.s-mmenu__gte').append( $('#google_translate_element') );
    }
  });

  $(window).resize(function(){
    if ($(window).width() > 767){
      if ($('.s-mmenu').hasClass('s-mmenu--show')){
        $('.s-mmenu').removeClass('s-mmenu--show');
        $('.s-headbar__gte').append( $('#google_translate_element') );
      }
    }
  });

});