$(document).ready(function(){

  $('.c-submenu__toggle').click(function(evt){
    evt.preventDefault();
    var lvl0_wrapper = $('.c-submenu__lvl0-wrapper');
    var arrow = $('.c-submenu__arrow');
    lvl0_wrapper.toggleClass('c-submenu__lvl0-wrapper--open');
    arrow.toggleClass('c-submenu__arrow--down');
  });

  $('.c-submenu__lvl1-arrow').click(function(evt){
    evt.preventDefault();
    var lvl1_wrapper = $(this).closest('.c-submenu__lvl1-wrapper');
    lvl1_wrapper.toggleClass('c-submenu__lvl1-wrapper--open');
  });

  $('.c-submenu__lvl2-arrow').click(function(evt){
    evt.preventDefault();
    var lvl2_wrapper = $(this).closest('.c-submenu__lvl2-wrapper');
    lvl2_wrapper.toggleClass('c-submenu__lvl2-wrapper--open');
  });

  $('.c-submenu__lvl3-arrow').click(function(evt){
    evt.preventDefault();
    var lvl3_wrapper = $(this).closest('.c-submenu__lvl3-wrapper');
    lvl3_wrapper.toggleClass('c-submenu__lvl3-wrapper--open');
  });

});
