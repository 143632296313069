$(document).ready(function(){

  $('.c-preset').each(function(){
    var preset = $(this);
    var input = preset.find('.c-preset__value');
    var other = preset.find('.c-preset__other');
    var rate = parseFloat(preset.attr('data-exchange'));

    var name = input.attr('name');
    var options = preset.find("input[type='radio'][class='c-preset__option']");

    options.change(function(){
      other.val('');
      input.val(this.value);
    });

    other.focus(function(){
      var amount = parseFloat(other.val())
      var convert = amount / rate;
      var pounds = Math.round((convert + Number.EPSILON) * 100) / 100
      input.val(pounds);
      options.prop('checked', false);
    });

    other.change(function(){
      var amount = parseFloat(other.val())
      var convert = amount / rate;
      var pounds = Math.round((convert + Number.EPSILON) * 100) / 100
      input.val(pounds);
    });

  });

});