$(document).ready(function(){
  $('.banner-owl-carousel').owlCarousel({
    'center': true,
    'loop': true,
    'items': 1,
    'dots': true,
    'autoplay':true,
    'autoplayTimeout':3000,
    'autoplayHoverPause':true,
  });
});