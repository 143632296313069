var amount_change = new Event('amount_change');

$(document).ready(function(){

  $('.c-number__btn--less').click(function(evt){
    evt.preventDefault();
    var change = false;
    var number = $(this).closest('.c-number');
    var input = number.find('.c-number__input');
    var value = parseInt(input.val());
    var attr = input.attr('min');
    if (typeof attr !== 'undefined' && attr !== false) {
      min = parseInt(attr);
      if (value > min){
        value = value - 1;
        change = true;
      }
    } else {
      value = value - 1;
      change = true;
    }
    input.val(value);
    if (change) {input[0].dispatchEvent(amount_change);}
  });

  $('.c-number__btn--more').click(function(evt){
    evt.preventDefault();
    var change = false;
    var number = $(this).closest('.c-number');
    var input = number.find('.c-number__input');
    var value = parseInt(input.val());
    var attr = input.attr('max');
    if (typeof attr !== 'undefined' && attr !== false) {
      max = parseInt(attr);
      if (value < max){
        value = value + 1;
        change = true;
      }
    } else {
      value = value + 1;
      change = true;
    }
    input.val(value);
    if (change) {input[0].dispatchEvent(amount_change);}
  });

});