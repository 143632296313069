$(document).ready(function(){

  $(document).on('change', '.js-terms--open', function(){
    var terms = $('.js-terms--open');
    if (terms.prop('checked')){
      $('#termsModal').modal('show');
    }
  })

  $(document).on('click', '.js-terms--accept', function(evt){
    evt.preventDefault();
    $('#termsModal').modal('hide');
  })

  $(document).on('click', '.js-terms--reject', function(evt){
    evt.preventDefault();
    $('.js-terms--open').prop('checked', false);
    $('#termsModal').modal('hide');
  })

});
