$(document).ready(function(){

  if ($('.s-events--all').length > 0){

    var filter = $('.s-events__filter');
    var query = $('.s-events__query');
    var type = $('.s-events__type');
    var submit = $('.s-events__submit');

    type[0].addEventListener('selection', function (e) {
      if (type.val() !== ''){
        filter.attr('action', '/get-involved/crps-awareness/crps-awareness-events/type/' + type.val() + '/');
      }
      if (type.val() == '*'){
        filter.attr('action', '/get-involved/crps-awareness/crps-awareness-events');
      }
      filter.submit();
    }, false);

    submit.click(function(){
      if (type.val() !== ''){
        filter.attr('action', '/get-involved/crps-awareness/crps-awareness-events/type/' + type.val() + '/');
      }
      if (type.val() == '*'){
        filter.attr('action', '/get-involved/crps-awareness/crps-awareness-events/');
      }
      filter.submit();
    });

  }


  if ($('.s-events--crps').length > 0){

    var filter = $('.s-events__filter');
    var query = $('.s-events__query');
    var type = $('.s-events__type');
    var submit = $('.s-events__submit');

    type[0].addEventListener('selection', function (e) {
      if (type.val() !== ''){
        filter.attr('action', '/get-involved/crps-events/type/' + type.val() + '/');
      }
      if (type.val() == '*'){
        filter.attr('action', '/get-involved/crps-events');
      }
      filter.submit();
    }, false);

    submit.click(function(){
      if (type.val() !== ''){
        filter.attr('action', '/get-involved/crps-events/type/' + type.val() + '/');
      }
      if (type.val() == '*'){
        filter.attr('action', '/get-involved/crps-events/');
      }
      filter.submit();
    });

  }

});
