$(document).ready(function(){

  if ($('.s-rs-opps').length > 0){

    var filter = $('.s-rs-opps__filter');
    var query = $('.s-rs-opps__query');
    var category = $('.s-rs-opps__category');
    var status = $('.s-rs-opps__status');
    var sort = $('.s-rs-opps__sort');
    var submit = $('.s-rs-opps__submit');

    category[0].addEventListener('selection', function (e) {
      if (category.val() !== ''){
        filter.attr('action', '/crps/clinical-trials/research-study-opportunities/category/' + category.val() + '/' + window.location.search);
      }
      filter.submit();
    }, false);

    sort[0].addEventListener('selection', function (e) {
      if (category.val() !== ''){
        filter.attr('action', '/crps/clinical-trials/research-study-opportunities/category/' + category.val() + '/' + window.location.search);
      }
      filter.submit();
    }, false);

    status[0].addEventListener('selection', function (e) {
      if (category.val() !== ''){
        filter.attr('action', '/crps/clinical-trials/research-study-opportunities/category/' + category.val() + '/' + window.location.search);
      }
      filter.submit();
    }, false);

    submit.click(function(){
      if (category.val() !== ''){
        filter.attr('action', '/crps/clinical-trials/research-study-opportunities/category/' + category.val() + '/' + window.location.search);
      }
      filter.submit();
    });

  }

});
