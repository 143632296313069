$(document).ready(function(){

  if ($('.c-summary--cart').length > 0){

    $(document).on('submit', '.c-summary__discount-form', function(evt){
      evt.preventDefault();
      $( '.ajax-summary' ).load( '/ajax/shop/cart/summary/add-discount/', $(this).serialize());
    });

    $(document).on('click', '.c-summary__remove', function(evt){
      evt.preventDefault();
      var remove = $(this);
      var discount_code = remove.attr('data-code');
      $( '.ajax-summary' ).load( '/ajax/shop/cart/summary/remove-discount/', {'discount_code': discount_code});
    });

  }

  if ($('.c-summary--review').length > 0){

    $(document).on('submit', '.c-form--donation', function(evt){
      evt.preventDefault();
      $.get('/ajax/shop/cart/summary/update-donation/', $(this).serialize(), function(response) {
        $( '.ajax-summary' ).html(response);
        $( '.ajax-summary' ).find('.js-visible-on-donation').show();
      });
    });

    $(document).on('change', '#id_donate', function(){
      var checked = $(this).prop('checked');
      $.get('/ajax/shop/cart/summary/toggle-donation/?switch=' + checked, function(response) {
        $( '.ajax-summary' ).html(response);
        if (checked) {
          $( '.ajax-summary' ).find('.js-visible-on-donation').show();
        } else {
          $( '.ajax-summary' ).find('.js-visible-on-donation').hide();
        }
      });
    });

    $(document).on('click', '.c-summary__apply', function(evt){
      var value = $('#id_donation').val();
      var re = /^\s*-?\d+(\.\d{1,2})?\s*$/
      if (re.test(value)) {
        if (parseFloat(value) === 0) {
          evt.preventDefault();
        }
      } else {
        evt.preventDefault();
      }
    })

  }

});
