$(document).ready(function(){

  if ($('.s-news').length > 0){

    var filter = $('.s-news__filter');
    var query = $('.s-news__query');
    var category = $('.s-news__category');
    var submit = $('.s-news__submit');

    category[0].addEventListener('selection', function (e) {
      if (category.val() !== ''){
        filter.attr('action', '/support/news-and-blog/category/' + category.val() + '/');
      }
      filter.submit();
    }, false);

    submit.click(function(){
      if (category.val() !== ''){
        filter.attr('action', '/support/news-and-blog/category/' + category.val() + '/');
      }
      filter.submit();
    });

  }

});
