$(document).ready(function(){
  $('.testimonial-owl-carousel').owlCarousel({
    'center': true,
    'loop': true,
    'items': 2,
    'dots': false,
    'responsive' : {
      0: {
        'margin': 10,
      },
      768: {
        'margin': 30,
      }
    }
  });

  $(document).on('click', '.c-testimonials__owl-prev', function(evt){
    evt.preventDefault();
    $('.c-testimonials .owl-prev').trigger('click');
  });

  $(document).on('click', '.c-testimonials__owl-next', function(evt){
    evt.preventDefault();
    $('.c-testimonials .owl-next').trigger('click');
  });

});