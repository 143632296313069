$(document).ready(function(){

  // Draw Star Rating
  function draw_stars(stars, value){
    var html = '';
    var all_stars = stars.find('.c-rate__star');
    for (var i = 1; i <= 5; i++) {
      star = i - 1;
      if ( i > value ){
        $(all_stars[star]).removeClass('fas').addClass('far');
      } else {
        $(all_stars[star]).removeClass('far').addClass('fas');
      }
    }
  }

  // Preset stars
  $('.c-rate').each(function(){
    var rate = $(this);
    var input = rate.find('.c-rate__input');
    var stars = rate.find('.c-rate__stars');
    var value = input.val();
    draw_stars(stars, value);
  });

  // Hover state
  $('.c-rate__star').hover(
    function(){
      var star = $(this)
      var rate = $(this).closest('.c-rate');
      var stars = rate.find('.c-rate__stars');
      var value = parseInt(star.attr('data-rate'));
      draw_stars(stars, value);
    },
    function(){
      var rate = $(this).closest('.c-rate');
      var input = rate.find('.c-rate__input');
      var stars = rate.find('.c-rate__stars');
      var value = input.val();
      draw_stars(stars, value);
    }
  );

  // Change state
  $('.c-rate__star').click(function(evt){
    evt.preventDefault();
    var star = $(this);
    var rate = star.closest('.c-rate');
    var stars = rate.find('.c-rate__stars');
    var value = parseInt(star.attr('data-rate'));
    var input = rate.find('.c-rate__input');
    input.val(value);
    draw_stars(stars, value);
  });

});