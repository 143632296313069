$(document).ready(function(){

  if ($('.s-f-category').length > 0){

    var filter = $('.s-f-category__filter');
    var sort = $('.s-f-category__sort');

    sort[0].addEventListener('selection', function (e) {
      filter.submit();
    }, false);

  }

});
