$(document).ready(function(){

  var hidden_arr = [];

  $('.c-form__group--condition').each(function(){
    var condition = $(this);
    var field = condition.attr('data-condition-field');
    var values = condition.attr('data-condition-values');
    var values_arr = values.split(',');
    var conditioner = condition.find('select, input');

    var form_group = $('.c-form__group[data-field="' + field + '"]');
    var optioner = form_group.find('select, input[type="radio"], input[type="checkbox"]');

    // Mark Mandatory Conditional Fields
    if (conditioner.prop('required')){
      conditioner.addClass('mandatory');
      hidden_arr.push(conditioner.attr('name'));
    }

    function array_add(arr, value){
      if (!arr.includes(value)){
        arr.push(value);
        console.log('add', value);
      }
    }

    function array_remove(arr, value){
      var i = arr.indexOf(value);
      if (i > -1) {
        arr.splice(i, 1);
      }
    }

    function optioner_change(){
      if (optioner.is('input[type="checkbox"], input[type="radio"]')){

        var find_arr = [];
        form_group.find('input[type="checkbox"], input[type="radio"]').each(function(){
          var checkbox = $(this);
          if (checkbox.prop('checked')){
            find_arr.push(checkbox.attr('value'));
          }
        });

        var show = false;
        for (var i = 0; i < find_arr.length; i++) {
          if (values_arr.includes(find_arr[i].toString())){
            show = true;
            break;
          }
        }

        var conditioner = condition.find('select, input');

        if (show){
          condition.css('display', 'block');
          if (conditioner.hasClass('mandatory')){
            conditioner.prop('required', true);
            array_remove(hidden_arr, conditioner.attr('name'));
          }
        } else {
          condition.css('display', 'none');
          if (conditioner.hasClass('mandatory')){
            conditioner.prop('required', false);
            array_add(hidden_arr, conditioner.attr('name'));
          }
        }

      }

      if (optioner.is('select')){

        var find = optioner.val().toString();
        var conditioner = condition.find('select, input');

        if (values_arr.includes(find)){
          condition.css('display', 'block');
          if (conditioner.hasClass('mandatory')){
            conditioner.prop('required', true);
            array_remove(hidden_arr, conditioner.attr('name'));
          }
        } else {
          condition.css('display', 'none');
          if (conditioner.hasClass('mandatory')){
            conditioner.prop('required', false);
            array_add(hidden_arr, conditioner.attr('name'));
          }
        }

      }

      // Populate Hidden Fields
      $('input[name="hidden"]').val(hidden_arr.join());

    }

    optioner_change();
    optioner.change(function(evt){
      optioner_change();
    });

  });

});