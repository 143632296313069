$(document).ready(function(){

  $('.s-menu__sb-wrap').hover(function(){
    var window_width = $(window).width();
    var sa_area = $(this).closest('.s-menu__sa-area');
    var sc_item_count = $(this).find('.s-menu__sc-item').length;
    var width = 235 + Math.ceil(sc_item_count / 7) * 363;
    if (window_width < 1399){
      width = 235 + 363;
    }
    sa_area.css('width', width + 'px');
  });

  // Active State

  $('.s-menu__item[data-slug="' + $('.s-menu--cache').attr('data-slug') + '"]').addClass(
    $('.s-menu__item[data-slug="' + $('.s-menu--cache').attr('data-slug') + '"]').attr('data-active')
  );

});