$(document).ready(function(){

  $('.s-mmenu__lvl1-arrow').click(function(evt){
    evt.preventDefault();
    var lvl1_wrapper = $(this).closest('.s-mmenu__lvl1-wrapper');
    lvl1_wrapper.toggleClass('s-mmenu__lvl1-wrapper--open');
  });

  $('.s-mmenu__lvl2-arrow').click(function(evt){
    evt.preventDefault();
    var lvl2_wrapper = $(this).closest('.s-mmenu__lvl2-wrapper');
    lvl2_wrapper.toggleClass('s-mmenu__lvl2-wrapper--open');
  });

  $('.s-mmenu__lvl3-arrow').click(function(evt){
    evt.preventDefault();
    var lvl3_wrapper = $(this).closest('.s-mmenu__lvl3-wrapper');
    lvl3_wrapper.toggleClass('s-mmenu__lvl3-wrapper--open');
  });

});