$(document).ready(function(){

  if ($('.c-cprod').length > 0){

    // Update Amount
    $('.c-cprod__amount').each(function(){
      $(this)[0].addEventListener('amount_change', function (evt) {
        var amount_el = $(evt.target);
        var product_id = amount_el.attr('data-product');
        var option_ids = amount_el.attr('data-options');
        var quantity = amount_el.val();
        var total_el = $('.s-headlogo__total');
        var template = $('.ajax-summary').attr('data-template');

        $.ajax({
          type: 'post',
          url: '/ajax/shop/cart/price-update/',
          data: {
            'product_id': product_id,
            'option_ids': option_ids,
            'quantity': quantity,
          },
          traditional: true,
          success: function (data) {
            // Update Summary
            $.ajax({
              type: 'get',
              url: "/ajax/shop/cart/summary/update/",
              data: {
                'template': template,
              },
              cache: false,
            })
            .done(function( html ) {
              $( '.ajax-summary' ).html( html );
              total_el.text($(html).find('.c-summary__total').text());
            });
          }
        });

      }, false);
    });

    // Remove From Cart
    $('.c-cprod__remove').click(function(evt){

      var remove_el = $(this);
      var product_id = remove_el.attr('data-product');
      var option_ids = remove_el.attr('data-options');
      var cprod_el = remove_el.closest('.c-cprod');
      var total_el = $('.s-headlogo__total');
      var template = $('.ajax-summary').attr('data-template');

      evt.preventDefault();
      $.ajax({
        type: 'post',
        url: '/ajax/shop/cart/remove/',
        data: {
          'product_id': product_id,
          'option_ids': option_ids,
        },
        traditional: true,
        success: function (data) {

          // Remove From Browser
          cprod_el.remove();

          $.ajax({
            type: 'get',
            url: "/ajax/shop/cart/summary/update/",
            data: {
              'template': template,
            },
            cache: false,
          })
          .done(function( html ) {
            $( '.ajax-summary' ).html( html );
            var item_count = $('.c-summary__attrib').first().text();

            total_el.text($(html).find('.c-summary__total').text());
            $('.s-headlogo__quantity').text('(' + item_count + ')')
          });

        }
      });
    });

  }
});
