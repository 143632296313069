const selection_event = new Event('selection');

$(document).ready(function(){

  // Preset selection
  $('.c-select').each(function(){
    var select = $(this);
    var input = select.find('.c-select__input');
    var title = select.find('.c-select__title');
    var option = select.find(".c-select__option[data-id='" + input.val() + "']");

    option.addClass('c-select__option--selected');
    if (option.length > 0){
      title.text(option[0].text);
    }
  });

  // Allow dropdown
  $('.c-select__header').click(function(evt){
    evt.preventDefault();
    var header = $(this);
    var select = header.closest('.c-select');
    var body = select.find('.c-select__body');

    if (select.hasClass('c-select--open')){
      select.removeClass('c-select--open');
    } else {
      select.addClass('c-select--open');
    }
  });

  // Update selection
  $('.c-select__option').click(function(evt){
    evt.preventDefault();
    var option = $(this);
    var select = option.closest('.c-select');
    var title = select.find('.c-select__title');
    var input = select.find('.c-select__input');

    if (option.length > 0){
      title.text(option[0].text);
      input.val(option.attr('data-id'));
      input[0].dispatchEvent(selection_event);
      select.find('.c-select__option--selected').removeClass('c-select__option--selected');
      option.addClass('c-select__option--selected');
      select.removeClass('c-select--open');
    }

  });

});